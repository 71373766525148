import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { usersRoutes } from './users';
import { companiesRoutes } from './companies';
import { integrationsRoutes } from './integrations';
import { insightsRoutes } from './insights';
import { productsRoutes } from './products';
import { feedbackRoutes } from './feedback';
import { metricsRoutes } from './metrics';
import { crmRoutes } from './crm';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Feedback routes
    ...feedbackRoutes,

    // Users routes
    ...usersRoutes,

    // Metrics routes
    ...metricsRoutes,

    // Companies routes
    ...companiesRoutes,

    // Products routes
    ...productsRoutes,

    // Integrations routes
    ...integrationsRoutes,

    // INSIGHTS routes
    ...insightsRoutes,

    // Main routes
    ...mainRoutes,

    // CRM routes
    ...crmRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
