import TryIcon from '@mui/icons-material/Try';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton';

import FeedbackDialog from 'src/components/custom-dialog/feedback-dialog';
import { useBoolean } from '../../hooks/use-boolean';

export default function SendFeedbackPopover() {
  const { t } = useTranslation();
  const open = useBoolean(false);

  return (
    <>
      <Tooltip title={t('text.labels.sendFeedback')}>
        <IconButton onClick={open.onTrue} sx={{ ml: 1 }} color="primary">
          <TryIcon />
        </IconButton>
      </Tooltip>
      <FeedbackDialog open={open.value} onClose={open.onFalse} />
    </>
  );
}
