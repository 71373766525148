import getHeader from './header';
import { clearFilterToSend } from '../components/dataTable/utils';
import axios, { endpoints } from '../utils/axios';
import { Product } from '../models/product';

export async function getProducts(
  companyId: string,
  skip: number = 0,
  limit: number = 500,
  order: string = 'name DESC',
  filters?: any,
  fields?: any
): Promise<Product[]> {
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      where: { companyId, ...clearFilterToSend(filters, 'products') },
      order: order || 'name DESC',
      fields,
    };

    const response = await axios.get(
      `${endpoints.products.list}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }

    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getProduct(id: string): Promise<Product | null> {
  try {
    const header = await getHeader();

    const response = await axios.get(`${endpoints.products.id(id)}`, header);

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getProductsCount(companyId: string, filters?: any): Promise<number> {
  try {
    const header = await getHeader();
    const filter = { companyId, ...clearFilterToSend(filters, 'products') };

    const response = await axios.get(
      `${endpoints.products.count}?where=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data.count;
    }
    return 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export async function postProduct(data: any): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.post(`${endpoints.products.list}`, data, header);

    return response.status === 200;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function patchProduct(data: any, id: string): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.patch(`${endpoints.products.list}/${id}`, data, header);

    return response.status > 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}
