export class Performance {
  answerRate: number;

  bounced: number;

  clickRate: number;

  openRate: number;

  spamReported: number;

  successfulDeliveries: number;

  totalSessions: number;

  unsubscribes: number;

  constructor(data?: any) {
    this.answerRate = data?.answerRate || 0;
    this.bounced = data?.bounced || 0;
    this.clickRate = data?.clickRate || 0;
    this.openRate = data?.openRate || 0;
    this.spamReported = data?.spamReported || 0;
    this.successfulDeliveries = data?.successfulDeliveries || 0;
    this.totalSessions = data?.totalSessions || 0;
    this.unsubscribes = data?.unsubscribes || 0;
  }
}
