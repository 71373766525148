/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react';
// @mui
import Card, {CardProps} from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import {FormGroup} from '@mui/material';
import {useTranslation} from 'react-i18next';
// @types
import {OptionConfig, OptionType, renderDefaultValue} from '../../models/optionConfig';
// utils
import {
  renderCheckboxList,
  renderColorPicker,
  renderCountriesPicker,
  renderDateInput,
  renderInput,
  renderLanguagePicker,
  renderMultipleChoise,
  renderSwitchButton,
} from './utils';
import {CustomFile, UploadAvatar} from '../upload';
import {fData} from '../../utils/format-number';

// eslint-disable-next-line import/no-cycle
import {useDataContext} from '../data';
import MultipleChoseImageList from './MultipleChoseImageList';
import {postCompanyFavIcon, postCompanyLogo} from '../../services/companies';
import {useSnackbar} from '../snackbar';
import {postIntegrationImageUrl} from '../../services/integrations';
import InputMultiSelectList from './utils/InputMultiSelectList';

interface Props extends CardProps {
  requests: OptionConfig[];
  onChangeRequest: (form: Record<string, any>) => void;
  width?: number | string;
  card?: boolean;
  list?: boolean;
  item?: boolean;
}

const DynamicRequestForm = ({
  requests,
  onChangeRequest,
  width = 480,
  sx,
  card = false,
  list = false,
  item = false,
  ...other
}: Props) => {
  const { t } = useTranslation();
  const {
    language,
    selectedCompany,
    metricFilter,
    productsFilter,
    integrationsFilter,
    campaignFilter,
  } = useDataContext();
  const { enqueueSnackbar } = useSnackbar();

  const [dictionary, setDictionary] = useState<Record<string, any>>({});

  const update = (id: string, value: any) => {
    const req = requests.find((request) => request.ref === id);
    if (req) {
      switch (req.type) {
        case OptionType.MULTIPLECHOISE_IMAGE:
          req.defaultValues[0] = value;
          break;
        default:
          req.defaultValues[0] = value.toString();
      }
    }
    setDictionary((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const renderLabel = (name: string | any, require?: boolean) => {
    const res =
      name[language] ||
      name.en ||
      (t(`text.inputs.${name}`) === `text.inputs.${name}` ? name : t(`text.inputs.${name}`));

    return `${res}${require ? '*' : ''}`;
  };

  const handleDropImage = useCallback((key: string, acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      switch (key) {
        case 'logo':
          postCompanyLogo(selectedCompany, newFile).then((res) => {
            if (res) {
              enqueueSnackbar(t('success.logoUpdated'), { variant: 'success' });
              update(key, res);
            } else {
              enqueueSnackbar(t('error.logoUpdated'), { variant: 'error' });
            }
          });
          break;
        case 'favIcon':
          postCompanyFavIcon(selectedCompany, newFile).then((res) => {
            if (res) {
              enqueueSnackbar(t('success.faviconUpdated'), { variant: 'success' });
              update(key, res);
            } else {
              enqueueSnackbar(t('error.faviconUpdated'), { variant: 'error' });
            }
          });
          break;
        default:
          postIntegrationImageUrl(newFile).then((res) => {
            if (res) {
              enqueueSnackbar(t('success.imageUploaded'), { variant: 'success' });
              update(key, res);
            } else {
              enqueueSnackbar(t('error.imageUploaded'), { variant: 'error' });
            }
          });
          break;
      }
    }
  }, []);

  const renderQuestion = (request: OptionConfig) => {
    switch (request.type) {
      case OptionType.MULTIPLECHOICE:
      case OptionType.SELECT:
        return renderMultipleChoise(
          dictionary[request.ref],
          update,
          request.ref,
          renderLabel(request.name, request.require),
          request.defaultValues[0],
          request.possibleValues,
          false,
          {}
        );
      case OptionType.DATE:
        return renderDateInput(
          update,
          request.ref,
          null,
          renderLabel(request.name, request.require),
          { mb: 2 }
        );
      case OptionType.NUMBER:
      case OptionType.TEXT:
      case OptionType.LONGTEXT:
        return renderInput(
          dictionary[request.ref],
          update,
          request.ref,
          renderLabel(request.name, request.require),
          request.defaultValues[0],
          request.type === OptionType.NUMBER ? 'number' : 'text',
          request.type === OptionType.LONGTEXT,
          renderLabel(request.name, request.require),
          {},
          false,
          () => {}
        );
      case OptionType.PASSWORD:
        return renderInput(
          dictionary[request.ref],
          update,
          request.ref,
          renderLabel(request.name, request.require),
          request.defaultValues[0],
          'password',
          false,
          ''
        );
      case OptionType.EMAIL:
        return renderInput(
          dictionary[request.ref],
          update,
          request.ref,
          renderLabel(request.name, request.require),
          request.defaultValues[0],
          'email',
          false,
          ''
        );
      case OptionType.BOOLEAN:
        return renderSwitchButton(
          request.ref,
          renderLabel(request.name, request.require),
          request.defaultValues[0] === 'true',
          update,
          null
        );
      case OptionType.RADIO:
        return renderCheckboxList(
          update,
          request.ref,
          renderLabel(request.name, request.require),
          request.possibleValues as string[]
        );
      case OptionType.COLOR:
        return renderColorPicker(
          request.ref,
          dictionary[request.ref] as string,
          update,
          renderLabel(request.name, request.require)
        );
      case OptionType.FAVICON:
      case OptionType.LOGO:
        return (
          <UploadAvatar
            file={dictionary[request.ref] as CustomFile | string | null}
            onDrop={(acceptedFiles) => handleDropImage(request.ref, acceptedFiles)}
            placeholder={renderLabel(request.name, request.require)}
          />
        );
      case OptionType.IMAGE:
        return (
          <UploadAvatar
            file={dictionary[request.ref] as CustomFile | string | null}
            onDrop={(acceptedFiles) => handleDropImage(request.ref, acceptedFiles)}
            placeholder={renderLabel(request.name, request.require)}
            maxSize={1048576 * 4}
            helperText={t('text.labels.allowedFormatAndSize') + fData(1048576 * 4)}
            sx={{ height: 50, width: 200 }}
          />
        );

      case OptionType.MULTIPLECHOISE_IMAGE:
        return (
          <MultipleChoseImageList
            values={dictionary[request.ref]}
            max={
              request.possibleValues && request.possibleValues.length > 0
                ? Number(request.possibleValues[0])
                : 15
            }
            onChange={(value: any) => update(request.ref, value)}
          />
        );
      case OptionType.COUNTRY:
        return renderCountriesPicker(
          dictionary[request.ref],
          renderLabel(request.name, request.require),
          (value: any) => update(request.ref, value),
          false
        );
      case OptionType.LANGUAGE:
        return renderLanguagePicker(
          request.possibleValues as string[],
          dictionary[request.ref] || 'en',
          t('text.inputs.language'),
          (value: any) => update(request.ref, value),
          false
        );
      case OptionType.MICRODECIMAL:
        return <></>;
      case OptionType.METRIC:
        return <></>;
      case OptionType.CATEGORY:
        return <></>;
      case OptionType.INTEGRATION:
        return (
          <InputMultiSelectList
            key={request.ref}
            id={request.ref}
            label={renderLabel(request.name, request.require)}
            value={dictionary[request.ref] || request.defaultValues}
            values={integrationsFilter.map((i) => ({ value: i.id, label: i.name }))}
            update={(i, v) => update(request.ref, v)}
            sx={{}}
          />
        );
      case OptionType.PRODUCT:
        return (
          <InputMultiSelectList
            key={request.ref}
            id={request.ref}
            label={renderLabel(request.name, request.require)}
            value={dictionary[request.ref] || request.defaultValues}
            values={productsFilter.map((product) => ({ value: product.id, label: product.name }))}
            update={(i, v) => update(request.ref, v)}
            sx={{}}
          />
        );
      case OptionType.CAMPAIGN:
        return (
          <InputMultiSelectList
            key={request.ref}
            id={request.ref}
            label={renderLabel(request.name, request.require)}
            value={dictionary[request.ref] || request.defaultValues}
            values={campaignFilter.map((i) => ({ value: i.id, label: i.name }))}
            update={(i, v) => update(request.ref, v)}
            sx={{}}
          />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    onChangeRequest(dictionary);
  }, [dictionary]);

  useEffect(() => {
    setDictionary({
      ...requests?.reduce(
        (acc, request) => {
          acc[request.ref] = renderDefaultValue(request.type, request.defaultValues[0]);
          return acc;
        },
        {} as Record<string, any>
      ),
    });
  }, [requests]);

  if (card)
    return (
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          maxWidth: width,
          ...sx,
        }}
        {...other}
      >
        <FormGroup
          sx={{
            padding: 1,
            width: '100%',
          }}
        >
          {requests.map((request, index) => (
            <Stack key={index}>{renderQuestion(request)}</Stack>
          ))}
        </FormGroup>
      </Card>
    );

  if (list)
    return (
      <FormGroup
        sx={{
          padding: 1,
          width: '100%',
          ...sx,
        }}
      >
        <Stack spacing={1}>
          {requests.map((request, index) => (
            <Stack key={index}>{renderQuestion(request)}</Stack>
          ))}
        </Stack>
      </FormGroup>
    );

  return (
    <Stack spacing={2}>
      {requests.map((request, index) => (
        <Stack key={index}>{renderQuestion(request)}</Stack>
      ))}
    </Stack>
  );
};

export default DynamicRequestForm;
