// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  FEEDBACK: '/feedback',
  USERS: '/users',
  COMPANIES: '/companies',
  PRODUCTS: '/products',
  METRICS: '/metrics',
  COLLECTIONS: '/collections',
  INSIGHTS: '/insights',
  CRM: '/crm',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
  // FEEDBACK
  feedback: {
    root: ROOTS.FEEDBACK,
  },
  // USERS
  users: {
    root: ROOTS.USERS,
  },
  // COMPANIES
  companies: {
    root: ROOTS.COMPANIES,
  },
  // PRODUCTS
  products: {
    root: ROOTS.PRODUCTS,
  },
  // Metrics
  metrics: {
    root: ROOTS.METRICS,
  },
  // CRM
  crm: {
    root: ROOTS.CRM,
    contacts: `${ROOTS.CRM}/contacts`,
    organizations: `${ROOTS.CRM}/organizations`,
    campaign: {
      root: `${ROOTS.CRM}/campaign`,
      create: `${ROOTS.CRM}/campaign/create`,
      edit: (id: string) => `${ROOTS.CRM}/campaign/edit/${id}`,
      detail: (id: string) => `${ROOTS.CRM}/campaign/detail/${id}`,
      templates: {
        list: `${ROOTS.CRM}/campaign/templates`,
        edit: (id: string) => `${ROOTS.CRM}/campaign/templates/edit/${id}`,
      },
    },
  },
  // INSIGHTS
  insights: {
    issues: `${ROOTS.INSIGHTS}/issues`,
    validations: `${ROOTS.INSIGHTS}/validations`,
    requests: `${ROOTS.INSIGHTS}/market-problems`,
    love: `${ROOTS.INSIGHTS}/user-love`,
    profiles: `${ROOTS.INSIGHTS}/profiles`,
    reporting: {
      root: `${ROOTS.INSIGHTS}/reporting`,
      id: (id: string) => `${ROOTS.INSIGHTS}/reporting/${id}`,
    },
  },
  // COLLECTIONS
  collections: {
    root: ROOTS.COLLECTIONS,
    surveys: {
      root: `${ROOTS.COLLECTIONS}/surveys`,
      magicSurveyEdit: (id: string) => `${ROOTS.COLLECTIONS}/surveys/edit/${id}`,
    },
    EParty: `${ROOTS.COLLECTIONS}/integrations`,
    connections: `${ROOTS.COLLECTIONS}/integrations/manage`,
    themes: `${ROOTS.COLLECTIONS}/surveys/themes`,
    detail: (id: string) => `${ROOTS.COLLECTIONS}/surveys/detail/${id}`,
    add: {
      root: `${ROOTS.COLLECTIONS}/integrations/add`,
      drive: `${ROOTS.COLLECTIONS}/integrations/add/drive`,
      identity: (identity: string) => `${ROOTS.COLLECTIONS}/integrations/add/${identity}`,
      identityEdit: (identity: string, id: string) =>
        `${ROOTS.COLLECTIONS}/integrations/edit/${identity}/${id}`,
      magicSurveyEdit: (id: string) => `${ROOTS.COLLECTIONS}/surveys/edit/${id}`,
    },
  },
};
