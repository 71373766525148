import { FormControl, InputLabel } from '@mui/material';
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { InfoButton } from '../../infoButton/infoButton';

interface InputSelectListProps {
  id: string;
  label: string;
  value: string[];
  values: string[] | { label: any; value: string }[];
  update: (id: string, value: string[]) => void;
  sx?: any;
  helperText?: string;
  multiple?: boolean;
}

export default function InputMultiSelectList(props: InputSelectListProps) {
  const { value, values, update, id, label, helperText, multiple = true, sx } = props;

  const getObject = (i: string) =>
    // @ts-ignore
    values?.find((v) => (v.value || v) === i)?.label || i;
  return (
    <FormControl fullWidth>
      <InputLabel
        shrink
        sx={{
          fontSize: '1.1rem !important',
          color: (theme) => `${theme.palette.text.primary} !important`,
          ml: -1,
          ...(helperText && { mt: -1, display: 'flex', alignItems: 'center' }),
        }}
      >
        {label}
        {helperText && helperText !== '' && (
          <InfoButton text={helperText} size="medium" buttonSize={45} iconSize={25} />
        )}
      </InputLabel>
      <Autocomplete
        multiple={multiple}
        disableCloseOnSelect
        options={// @ts-ignore
        values?.filter((o: any) => !value.includes(o.value))}
        value={value}
        onChange={(event, newValue: any[]) => {
          // console.log('newValue', newValue);
          update(id, newValue?.filter((v) => v).map((v) => v?.value || v) || []);
        }}
        renderInput={(params) => <TextField placeholder={label} {...params} />}
        renderOption={(p, option) => (
          <li {...p} key={option.value}>
            {option.label}
          </li>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={`chip-${option}`}
              label={getObject(option)}
              size="small"
              variant="soft"
            />
          ))
        }
        sx={(theme) => ({
          my: 2,
          ...sx,
        })}
      />
    </FormControl>
  );
}
