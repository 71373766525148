/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDataContext } from '../../components/data';
import { getIntegrationOptionsList } from '../../services/integrations';
import { getCompanyProducts } from '../../services/companies';
import { getMetricsList } from '../../services/metrics';
import { getProducts } from '../../services/products';
import { getCampaigns } from '../../services/campaigns';

const callMetricsValues = (selectedCompany: string) => getMetricsList(selectedCompany);

export default function IntegrationPopover() {
  const {
    selectedCompany,
    updateCategories,
    updateIntegrationsFilter,
    updateMetricFilter,
    updateProductsFilter,
    updateFeedbackFilter,
    updateCampaignFilter,
  } = useDataContext();

  /*
  const groupMetrics = (res: IMetrics[]) => {
     const options: { group: string; value: string }[] = [];

     res.forEach((m) => {
       m.metrics_values.forEach((v) => {
         options.push({
           group: m.metrics_name,
           value: v,
         });
       });
     });

     updateMetricFilter(optio);
   };
   /*
     const getCompanyProductsByParts = useCallback(async () => {
       let offset = 0;
       const limit = 100;
       let products: any[] = [];
       let fetchedProducts;

       do {
         const promises = Array.from({ length: 5 }, (_, i) =>
           getProducts(selectedCompany, offset + i * limit, limit, '', {}, { id: true, name: true })
         );
         const results = await Promise.all(promises);
         fetchedProducts = results.flat();
         if (fetchedProducts.length > 0) {
           products = products.concat(fetchedProducts);
           offset += limit * promises.length;
         }
       } while (fetchedProducts.length === limit * promises.length);

       if (products.length > 0) updateProductsFilter(products);
     }, [selectedCompany]);

   const getMetricsListByParts = useCallback(async () => {
     let offset = 0;
     const limit = 50;
     let metrics: any[] = [];
     let fetchedMetrics;

     do {
       // eslint-disable-next-line no-await-in-loop
       fetchedMetrics = await callMetrics(selectedCompany, offset, limit);
       if (fetchedMetrics.length > 0) {
         metrics = metrics.concat(fetchedMetrics);
         offset += limit;
       }
     } while (fetchedMetrics.length === limit);

     if (metrics.length > 0) groupMetrics(metrics);
   }, [selectedCompany]);
 */
  useEffect(() => {
    if (!selectedCompany) return;

    getCompanyProducts(selectedCompany).then((res) => {
      if (res) updateCategories(res);
    });

    getProducts(selectedCompany, 0, 1000, 'name desc', {}, { id: true, name: true }).then((res) => {
      if (res) updateProductsFilter(res);
    });

    getIntegrationOptionsList(selectedCompany).then((res) => {
      if (res) updateIntegrationsFilter(res);
    });

    getCampaigns(
      selectedCompany,
      0,
      1000,
      'updatedAt DESC',
      {
        phase: ['ACTIVE', 'FINISHED'],
      },
      { id: true, name: true }
    ).then((res) => {
      if (res) updateCampaignFilter(res);
    });

    callMetricsValues(selectedCompany).then((res) => {
      if (res.length > 0) updateMetricFilter(res);
    });

    // getMetricsListByParts();
    // getCompanyProductsByParts();
  }, [selectedCompany]);

  return <></>;
}
