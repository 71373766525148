/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {useTranslation} from 'react-i18next';
import {FormControl} from '@mui/material';
import Iconify from 'src/components/iconify';

type Props = {
  filterString: string;
  onFilters: (name: string, value: string) => void;
  size?: 'small' | 'medium' | 'large' | 'auto' | number | string;
  border?: boolean;
  sx?: any;
};

const FilterName = ({ filterString, onFilters, size = 'auto', border = true, sx }: Props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const inputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      onFilters('name', value?.toLowerCase());
    }, 500);

    setTimer(newTimer);
  };

  useEffect(() => {
    if (filterString === '') {
      setInputValue('');
    }
  }, [filterString]);

  useEffect(
    () => () => {
      if (timer) {
        clearTimeout(timer);
      }
    },
    [timer]
  );

  useEffect(() => {
    setInputValue(filterString);
  }, [filterString]);

  const getSize = () => {
    switch (size) {
      case 'small':
        return 260;
      case 'medium':
        return 290;
      case 'large':
        return 367;
      case 'auto':
        return 'auto';
      default:
        return size;
    }
  };

  return (
    <FormControl>
      <TextField
        value={inputValue}
        onChange={inputChanged}
        placeholder={t('text.labels.search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
        variant={border ? 'outlined' : 'standard'}
        sx={{
          width: { xs: 1, md: getSize() },
          backgroundColor: 'background.paper',
          borderRadius: border ? 1 : 0,
          ...sx,
        }}
      />
    </FormControl>
  );
};

export default FilterName;
