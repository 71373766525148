import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';

// ----------------------------------------------------------------------

const EParty = lazy(() => import('src/pages/integrations/3rdPartiesList'));
const SurveyList = lazy(() => import('src/pages/integrations/surveysList'));
const AddIntegration = lazy(() => import('src/pages/integrations/add'));
const AddIntegrationForm = lazy(() => import('src/pages/integrations/addIntegrationForm'));
const Connections = lazy(() => import('src/pages/integrations/connections'));
const DriveIntegration = lazy(() => import('src/pages/integrations/drive'));
const MagicSurveyIntegration = lazy(() => import('src/pages/integrations/magicSurvey'));
const DetailSurveyIntegration = lazy(() => import('src/pages/integrations/detailSurvey'));
const ThemeList = lazy(() => import('src/pages/integrations/themeList'));
// ----------------------------------------------------------------------

export const integrationsRoutes = [
  {
    path: 'collections',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'integrations', element: <EParty /> },
      { path: 'surveys', element: <SurveyList /> },
      { path: 'integrations/manage', element: <Connections /> },
      { path: 'integrations/add', element: <AddIntegration /> },
      { path: 'integrations/add/drive', element: <DriveIntegration /> },
      { path: 'integrations/add/:identity', element: <AddIntegrationForm /> },
      { path: 'integrations/edit/:identity/:id', element: <AddIntegrationForm /> },
      { path: 'surveys/add', element: <MagicSurveyIntegration /> },
      { path: 'surveys/edit/:id', element: <MagicSurveyIntegration /> },
      { path: 'surveys/detail/:id', element: <DetailSurveyIntegration /> },
      { path: 'surveys/themes', element: <ThemeList /> },
    ],
  },
];
