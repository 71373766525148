// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
// models
import { useTranslation } from 'react-i18next';
import { InsightData } from '../../../models/insight-data';

interface Props {
  insights: InsightData[];
}

export const InsightsItemsList = ({ insights }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const style = { mt: 1.5, color: theme.palette.text.secondary };

  const parseExtract = (extract: string) => (extract ? `"${extract.trim()}"` : '');

  return (
    <Stack direction="column">
      {insights.map((insight) => (
        <Stack key={insight.id} sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ flexGrow: 1, color: theme.palette.text.secondary }}>
            {insight.name}
          </Typography>
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="start"
            width="100%"
            sx={{ pb: 1 }}
          >
            <Typography variant="body2" sx={style}>
              {t('text.labels.category')}: {insight.category}
            </Typography>
            {insight.topics?.length > 0 && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="start"
                width="100%"
                useFlexGap
                flexWrap="wrap"
                sx={{ pt: 1 }}
              >
                {insight.topics?.map(
                  (to, index) =>
                    to && (
                      <Chip
                        key={index}
                        label={to?.length > 0 ? `${to[0]?.toUpperCase()}${to?.slice(1)}` : ''}
                        size="small"
                        color="info"
                        sx={{ mr: 1, mb: 1 }}
                      />
                    )
                )}
              </Stack>
            )}
            <Typography variant="body2" sx={style}>
              {insight?.reason || ''}
            </Typography>
            <Typography variant="body2" sx={{ ...style, ...{ fontStyle: 'italic' } }}>
              {parseExtract(insight?.extracts || '')}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
