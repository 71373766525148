import React, { useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import { Dialog, DialogContent, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import FilterName from '../dataTable/elements/filterName';
import { renderTypeOrIcon } from '../dynamicRequestForm/utils';
// models
import { ReturnType } from '../custom-popover/use-popover';
import { IntegrationQuestionType } from '../../models/integration-question-type';
import { useDataContext } from '../data';
import Scrollbar from '../scrollbar';

interface Props {
  questionsTypePopover: ReturnType;
  onSelect: (ty: IntegrationQuestionType) => void;
  selected?: string;
}

export default function QuestionTypeDialog(props: Props) {
  const { questionsTypePopover, onSelect, selected } = props;
  const { integrationTypes } = useDataContext();
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');

  const categories = [
    { name: t('text.questionType.Text&Image'), values: ['TEXT', 'LONGTEXT', 'INFO_PAGE'] },
    {
      name: t('text.questionType.Ratings'),
      values: ['RATING_EMOJI', 'RATING_NUMBER', 'RATING_STAR'],
    },
    { name: t('text.questionType.Rankings'), values: ['PRIORITY_LIST', 'POINT_SYSTEM', 'MATRIX'] },
    {
      name: t('text.questionType.Choice'),
      values: ['RADIO', 'MULTIPLECHOICE', 'SELECT', 'BOOLEAN', 'MULTIPLECHOISE_IMAGE'],
    },
    {
      name: t('text.questionType.Other'),
      values: ['NUMBER', 'DATE', 'EMAIL', 'CONSENT', 'CONTACT'],
    },
  ];

  const renderList = (
    <Scrollbar sx={{ height: 600 }}>
      <Grid container spacing={2}>
        {categories.map((type) => (
          <Grid item key={type.name} xs={4}>
            <Stack key={type.name} spacing={1} direction="column" divider={<Divider />}>
              <Typography variant="subtitle2" gutterBottom ml={2} mt={2}>
                {type.name}
              </Typography>
              <Stack direction="column" spacing={2}>
                {type.values.map((va) => {
                  const ty = integrationTypes.find((it) => it.id === va);
                  if (!ty) return null;

                  return (
                    <ListItemButton
                      onClick={() => {
                        onSelect(ty);
                      }}
                      sx={{
                        borderRadius: 1,
                        ...(selected === ty.id && {
                          backgroundColor: (theme) => theme.palette.action.selected,
                        }),
                      }}
                    >
                      <ListItemIcon sx={{ ...(selected === ty.id && { color: 'primary.main' }) }}>
                        {renderTypeOrIcon(ty.id, { height: '20px' })}
                      </ListItemIcon>
                      <ListItemText
                        primary={t(`text.questionType.${ty.id}`)}
                        sx={{ ...(selected === ty.id && { color: 'primary.main' }) }}
                      />
                    </ListItemButton>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Scrollbar>
  );

  return (
    <Dialog
      open={!!questionsTypePopover.open}
      onClose={questionsTypePopover.onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          spacing={2}
          width={1}
        >
          <FilterName
            filterString={filter}
            size="small"
            onFilters={(name, value) => {
              setFilter(value);
            }}
          />
          <IconButton onClick={questionsTypePopover.onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
        {renderList}
      </DialogContent>
    </Dialog>
  );
}
