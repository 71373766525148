import { useCallback, useEffect, useState } from 'react';
import { m } from 'framer-motion'; // @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton'; // components
import i18next from 'i18next';
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useDataContext } from '../../components/data/context/index'; // ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export const allLangs = [
  // en
  {
    label: 'English',
    value: 'en',
    icon: 'flagpack:gb-nir',
    numberFormat: { code: 'en-US', currency: 'USD' },
  },
  // da
  {
    label: 'Dansk',
    value: 'da',
    icon: 'flagpack:dk',
    numberFormat: { code: 'da-DK', currency: 'DKK' },
  },
  // es
  {
    label: 'Español',
    value: 'es',
    icon: 'flagpack:es',
    numberFormat: { code: 'es-ES', currency: 'EUR' },
  },
  // fi
  {
    label: 'Suomi',
    value: 'fi',
    icon: 'flagpack:fi',
    numberFormat: { code: 'fi-FI', currency: 'EUR' },
  },
  // no
  {
    label: 'Norsk',
    value: 'no',
    icon: 'flagpack:no',
    numberFormat: { code: 'no-NO', currency: 'NOK' },
  },
  // sv
  {
    label: 'Svenska',
    value: 'sv',
    icon: 'flagpack:se',
    numberFormat: { code: 'sv-SE', currency: 'SEK' },
  },
];

export default function LanguagePopover({ hidden = false }: { hidden?: boolean }) {
  const popover = usePopover();
  const { language, updateLanguage } = useDataContext();

  const [currentLang, setCurrentLang] = useState<any>(allLangs[0]);

  const handleChangeLang = useCallback(
    (lang: string) => {
      updateLanguage(lang);
      popover.onClose();
    },
    [popover, updateLanguage]
  );

  useEffect(() => {
    if (language) {
      setCurrentLang(
        allLangs.find((option) => option?.value && option?.value === language) || allLangs[0]
      );
      i18next.changeLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      updateLanguage(storedLang);
    } else {
      const localLang = window.navigator.language.substring(0, 2);
      const lang = allLangs.find((option) => option?.value && option?.value === localLang);
      updateLanguage(lang ? lang.value : allLangs[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !hidden ? (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify icon={currentLang.icon} sx={{ borderRadius: 0.65, width: 28 }} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        {allLangs.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLang.value}
            onClick={() => handleChangeLang(option.value)}
          >
            <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />
            {option.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  ) : (
    <></>
  );
}
