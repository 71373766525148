export enum OptionType {
  MULTIPLECHOICE = 'MULTIPLECHOICE',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  TEXT = 'TEXT',
  LONGTEXT = 'LONGTEXT',
  PASSWORD = 'PASSWORD',
  EMAIL = 'EMAIL',
  COLOR = 'COLOR',
  FAVICON = 'FAVICON',
  LOGO = 'LOGO',
  IMAGE = 'IMAGE',
  MULTIPLECHOISE_IMAGE = 'MULTIPLECHOISE_IMAGE',
  COUNTRY = 'COUNTRY',
  LANGUAGE = 'LANGUAGE',
  MICRODECIMAL = 'MICRODECIMAL',
  METRIC = 'METRIC',
  CATEGORY = 'CATEGORY',
  INTEGRATION = 'INTEGRATION',
  PRODUCT = 'PRODUCT',
  CAMPAIGN = 'CAMPAIGN',
}

export const renderDefaultValue = (type: string, defaultValue: any) => {
  switch (type) {
    case OptionType.MULTIPLECHOICE:
    case OptionType.SELECT:
      return defaultValue;
    case OptionType.NUMBER:
      return Number(defaultValue);
    case OptionType.BOOLEAN:
      return defaultValue === 'true';
    case OptionType.MULTIPLECHOISE_IMAGE:
      return typeof defaultValue === 'string' ? defaultValue.split('|') : defaultValue;
    default:
      return defaultValue;
  }
};

export type Lang = {
  [id: string]: string;
};

export class OptionConfig {
  defaultValues: string[];

  name: Lang;

  possibleValues: string[] | number[];

  ref: string;

  require: boolean;

  type: OptionType;

  constructor(
    defaultValues: string[],
    name: Lang,
    possibleValues: string[] | number[],
    ref: string,
    require: boolean,
    type: OptionType
  ) {
    this.defaultValues = defaultValues;
    this.name = name;
    this.possibleValues = possibleValues;
    this.ref = ref;
    this.require = require;
    this.type = type;
  }
}
