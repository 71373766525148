import axios, { endpoints } from '../utils/axios';
import getHeader from './header';
import { clearFilterToSend } from '../components/dataTable/utils';
import { Status } from '../models/status';
import { Campaign } from '../models/campaign';
import { Session } from '../models/session';
import { Performance } from '../models/performance';
import { Template } from '../models/crm/template';

export async function getCampaigns(
  companyId: string,
  skip: number = 0,
  limit: number = 50,
  order: string = 'updatedAt DESC',
  filters?: any,
  fields?: any,
  include?: any[]
): Promise<Campaign[]> {
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      where: {
        companyId,
        status: Status.ACTIVE,
        ...clearFilterToSend(filters, 'campaigns'),
      },
      order: order || 'updatedAt DESC',
      fields,
      include,
    };

    const response = await axios.get(
      `${endpoints.campaigns.list}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCampaignsCount(companyId: string, filters?: any): Promise<number> {
  try {
    const header = await getHeader();

    const filter = {
      companyId,
      status: Status.ACTIVE,
      ...clearFilterToSend(filters, 'campaigns'),
    };

    const response = await axios.get(
      `${endpoints.campaigns.count}?where=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      // return 5
      return response.data.count;
    }
    return 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export async function postCampaigns(data: any): Promise<Campaign | null> {
  try {
    const header = await getHeader();

    const response = await axios.post(`${endpoints.campaigns.list}`, data, header);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }

    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function patchCampaigns(contactId: string, data: any): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.patch(`${endpoints.campaigns.id(contactId)}`, data, header);

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// patch phase(id:string, phase:string)
export async function patchCampaignsPhase(id: string, phase: string): Promise<boolean> {
  try {
    const header = await getHeader();

    const body = {
      phase,
    };

    const response = await axios.patch(`${endpoints.campaigns.phase(id)}`, body, header);

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// patch clone(id:string)
export async function patchCampaignsClone(id: string): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.patch(`${endpoints.campaigns.clone(id)}`, {}, header);

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function deleteCampaigns(contactId: string): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.delete(`${endpoints.campaigns.id(contactId)}`, header);

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function postCampaignContentWizard(query: string, campaignId: string): Promise<any> {
  try {
    const header = await getHeader();

    const body = {
      campaignId,
      query,
    };

    const response = await axios.post(`${endpoints.campaigns.contentWizard}`, body, header);

    return response.status >= 200 && response.status < 300 ? response.data : {};
  } catch (e) {
    console.error(e);
    return {};
  }
}

// get sessions-manager
export async function getSessions(
  id: string,
  fields?: any,
  skip: number = 0,
  limit?: number,
  filters?: any,
  order?: string
): Promise<Session[]> {
  try {
    const header = await getHeader();

    const filter: any = {
      where: { ...clearFilterToSend(filters, 'sessions') },
    };

    if (fields) filter.fields = fields;
    if (order) filter.order = order;
    if (limit) filter.limit = limit;
    if (skip > -1) filter.skip = skip;

    const response = await axios.get(
      `${endpoints.campaigns.sessions.list(id)}?filter=${JSON.stringify(filter)}`,
      header
    );

    return response.status >= 200 && response.status < 300 ? response.data : [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// get sessions-manager count
export async function getSessionsCount(id: string, filters: any): Promise<number> {
  try {
    const header = await getHeader();

    const filter = {
      ...clearFilterToSend(filters, 'sessions'),
    };

    const response = await axios.get(
      `${endpoints.campaigns.sessions.count(id)}?where=${JSON.stringify(filter)}`,
      header
    );

    return response.status >= 200 && response.status < 300 ? response.data.count : 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

// post session
export async function postSession(data: string[], id: string): Promise<any> {
  try {
    const header = await getHeader();

    const body = {
      crmContactId: data,
    };

    const response = await axios.post(`${endpoints.campaigns.sessions.list(id)}`, body, header);

    return response.status >= 200 && response.status < 300 ? response.data : {};
  } catch (e) {
    console.error(e);
    return {};
  }
}

// get seesions/feedback
export async function getSessionsFeedback(id: string, fields?: any): Promise<any[]> {
  try {
    const header = await getHeader();

    const filter: any = {};

    if (fields) filter.fields = fields;

    const response = await axios.get(
      `${endpoints.campaigns.sessions.feedback(id)}?filter=${JSON.stringify(filter)}`,
      header
    );

    return response.status >= 200 && response.status < 300 ? response.data : [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// post lunch
export async function postLaunch(id: string): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.post(`${endpoints.campaigns.launch(id)}`, {}, header);

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getPerformances(companyId: string, filters?: any): Promise<any> {
  try {
    const header = await getHeader();

    const filter: any = {
      where: {
        companyId,
      },
    };

    const response = await axios.get(
      `${endpoints.campaigns.performance}?filter=${JSON.stringify(filter)}`,
      header
    );

    return response.status >= 200 && response.status < 300 ? response.data : {};
  } catch (e) {
    console.error(e);
    return {};
  }
}

// get performance by id
export async function getPerformance(id: string): Promise<Performance> {
  try {
    const header = await getHeader();

    const response = await axios.get(`${endpoints.campaigns.performanceId(id)}`, header);

    return response.status >= 200 && response.status < 300 ? response.data[id] : {};
  } catch (e) {
    console.error(e);
    return new Performance();
  }
}

// get answers
export async function getAnswers(
  id: string,
  where: {
    metrics_name: string;
    metrics_values: string[];
  }[] = [],
  question?: Record<string, any>
): Promise<any> {
  try {
    const header = await getHeader();

    const filter: any = {};

    if (where?.filter((m) => m.metrics_values?.length > 0).length > 0) {
      filter.where = {
        feedbackMetric: {
          and: where
            ?.filter((m) => m.metrics_values?.length > 0)
            .map((m) => ({
              name: m.metrics_name,
              values: {
                inq: m.metrics_values,
              },
            })),
        },
      };
    }

    if (question) {
      filter.where = {
        ...filter.where,
        question: {
          ref: question.ref || '',
          refMetric: question.refMetric || '',
          type: question.type || '',
        },
      };
    }

    const response = await axios.get(
      `${endpoints.campaigns.sessions.answers(id)}?filter=${JSON.stringify(
        filter.where ? filter : {}
      )}`,
      header
    );

    return response.status >= 200 && response.status < 300 ? response.data : [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// get export google sheet
export async function getExportGoogleSheet(id: string): Promise<any> {
  try {
    const header = await getHeader();

    const response = await axios.post(`${endpoints.campaigns.sessions.export(id)}`, {}, header);

    return response.status >= 200 && response.status < 300 ? response.data : {};
  } catch (e) {
    console.error(e);
    return {};
  }
}

// get templates
export async function getTemplates(
  companyId: string,
  skip?: number,
  limit?: number,
  order?: string,
  filters?: any
): Promise<Template[]> {
  try {
    const header = await getHeader();

    const filter: any = {
      order: 'updatedAt DESC',
      where: {
        companyId,
        status: Status.ACTIVE,
      },
    };

    if (skip) filter.skip = skip;
    if (limit) filter.limit = limit;
    if (order) filter.order = order;
    if (filters) filter.where = { ...filter.where, ...clearFilterToSend(filters, 'campaigns') };

    const response = await axios.get(
      `${endpoints.campaigns.templates.list}?filter=${JSON.stringify(filter)}`,
      header
    );

    return response.status >= 200 && response.status < 300 ? response.data : [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// get templates count
export async function getTemplatesCount(companyId: string, filters?: any): Promise<number> {
  try {
    const header = await getHeader();

    const filter = {
      companyId,
      status: Status.ACTIVE,
      ...clearFilterToSend(filters, 'campaigns'),
    };

    const response = await axios.get(
      `${endpoints.campaigns.templates.count}?where=${JSON.stringify(filter)}`,
      header
    );

    return response.status >= 200 && response.status < 300 ? response.data.count : 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

// get templates by id
export async function getTemplateById(id: string): Promise<Template | null> {
  try {
    const header = await getHeader();

    const response = await axios.get(`${endpoints.campaigns.templates.id(id)}`, header);

    return response.status >= 200 && response.status < 300 ? response.data : {};
  } catch (e) {
    console.error(e);
    return null;
  }
}

// post templates
export async function postTemplates(data: any): Promise<Template | null> {
  try {
    const header = await getHeader();

    const response = await axios.post(
      `${endpoints.campaigns.templates.list}`,
      {
        companyId: data.companyId,
        name: data.name,
        description: data.description,
        template: data.template,
      },
      header
    );

    return response.status >= 200 && response.status < 300 ? response.data : null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

// patch templates
export async function patchTemplates(id: string, data: any): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.patch(`${endpoints.campaigns.templates.id(id)}`, data, header);

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// delete templates
export async function deleteTemplates(id: string): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.delete(`${endpoints.campaigns.templates.id(id)}`, header);

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}
