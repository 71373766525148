/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import { FormControl, ImageListItem, ImageListItemBar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
// @types
import { UploadAvatar } from '../upload';
import { postIntegrationImageUrl } from '../../services/integrations';
import { SortableContainer } from '../integration/dnd/sortable-container';

class ImageValue {
  id: string;

  position: number;

  url: string;

  value: string;

  constructor(position: number, url: string, value: string) {
    this.id = Math.random().toString(36).substr(2, 9);
    this.position = position;
    this.url = url;
    this.value = value;
  }
}

interface Props {
  values: string[];
  onChange: (value: any) => void;
  max?: number;
  sx?: any;
}

const MultipleChoseImageList = ({ values, onChange, max = 15, sx = {} }: Props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [imagesList, setImagesList] = useState<ImageValue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDropImage = useCallback(
    async (acceptedFiles: File[]) => {
      const newFile = acceptedFiles[0];
      if (newFile) {
        setLoading(true);
        const file = Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        });

        const url = await postIntegrationImageUrl(file);

        setLoading(false);
        return url;
      }
      return '';
    },
    [imagesList]
  );

  const handleDelete = (index: number) => {
    if (imagesList.length === 1) return;

    const list = [...imagesList];
    list?.splice(index, 1);

    // Update position
    list?.forEach((item, i) => {
      item.position = i + 1;
    });

    onChange(list);
  };

  useEffect(() => {
    console.log('values', values);
    if (values?.length > 0) {
      setImagesList(
        values.map((v, index) => {
          try {
            const x = JSON.parse(v);
            return new ImageValue(x.position, x.url, x.value);
          } catch (e) {
            console.error(e);
          }
          return new ImageValue(index + 1, '', '');
        })
      );
    }
  }, [values]);

  const dragList = (
    <Stack direction="column" spacing={2} width="100%">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        width="100%"
        mx={0.5}
      >
        <FormControl fullWidth sx={{ maxWidth: '85%' }}>
          <TextField
            error={error}
            InputLabelProps={{
              shrink: true,
            }}
            label={t('text.tables.responses')}
            variant="outlined"
            value={value}
            onChange={(e) => {
              if (imagesList.some((item) => item.value === e.target.value)) {
                setError(true);
                setErrorText('alreadyExists');
              } else {
                setError(false);
              }
              setValue(e.target.value);
            }}
            helperText={error ? `(${t(`error.${errorText}`)})` : ''}
          />
        </FormControl>
        {loading && <CircularProgress size="25px" />}
        {!loading && imagesList.length < max && (
          <UploadAvatar
            file={null}
            disabled={loading || error || value === ''}
            onDrop={async (acceptedFiles) => {
              const url = await handleDropImage(acceptedFiles);
              const f = new ImageValue(imagesList.length + 1, url, value);
              setValue('');
              setError(false);
              onChange([...imagesList, f]);
            }}
            maxSize={1048576 * 4}
            sx={{
              width: 60,
              height: 53,
            }}
          />
        )}
      </Stack>
      {imagesList?.length > 0 && (
        <SortableContainer
          idList={imagesList.map((f) => f.id)}
          swap
          layout="vertical"
          renderItem={(id) => {
            const f = imagesList.find((i) => i.id === id);
            if (!f) return <></>;
            return (
              <ItemImage
                key={f.id}
                f={f}
                index={imagesList.indexOf(f)}
                disabled={imagesList.length === 1}
                deleteImage={(i) => handleDelete(i)}
              />
            );
          }}
          sendNewOrder={(newOrder) => {
            const newList = newOrder.map((id, index) => {
              const f = imagesList.find((i) => i.id === id);
              if (!f) return null;
              f.position = index + 1;
              return f;
            });

            onChange(newList);
          }}
        />
      )}
    </Stack>
  );

  return dragList;
};

interface ItemImageProps {
  f: ImageValue;
  index: number;
  disabled: boolean;
  deleteImage: (i: number) => void;
}

function ItemImage({ f, index, disabled, deleteImage }: ItemImageProps) {
  return (
    <ImageListItem
      key={f.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        margin: 'auto',
      }}
    >
      <img
        srcSet={f.url}
        src={f.url}
        alt={f.value}
        loading="lazy"
        width="auto"
        height="100"
        style={{
          borderRadius: 1,
          objectFit: 'cover',
          maxWidth: '100%',
        }}
      />
      <ImageListItemBar
        title={f.value}
        actionIcon={
          <IconButton
            size="small"
            color="error"
            disabled={disabled}
            onClick={() => deleteImage(index)}
            sx={{ my: 'auto' }}
          >
            <CloseIcon width="20px" height="20px" />
          </IconButton>
        }
      />
    </ImageListItem>
  );
}

export default MultipleChoseImageList;
