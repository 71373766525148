export const getStatusImageColor = (status?: string) => {
  switch (status) {
    case 'ACTIVE':
    case 'DONE':
    case 'EMPTY':
      return 'green';
    case 'ERROR':
      return 'orange';
    case 'DEPRECATED':
    case 'REGISTERED':
    case 'PROCESSING':
      return 'gray';
    case 'PARTIAL':
      return 'orange';
    default:
      return 'gray';
  }
};

export const getStatusText = (status?: string) => {
  switch (status) {
    case 'ACTIVE':
      return 'Active';
    case 'DONE':
      return 'Done';
    case 'ERROR':
      return 'Error';
    case 'REGISTERED':
      return 'In queue';
    case 'DEPRECATED':
      return 'Deprecated';
    case 'PARTIAL':
      return 'Partial';
    case 'EMPTY':
      return 'Empty';
    case 'PROCESSING':
      return 'Processing';
    default:
      return 'Unknown';
  }
};

export const getStatusIcon = (status?: string) => {
  switch (status) {
    case 'ACTIVE':
    case 'DONE':
    case 'EMPTY':
      return 'done';
    case 'REGISTERED':
      return 'registered';
    case 'ERROR':
    case 'DEPRECATED':
    case 'PARTIAL':
      return 'warning';
    case 'PROCESSING':
      return 'processing';
    default:
      return 'processing';
  }
};
