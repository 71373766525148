import React, { useCallback, useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  Drawer,
  drawerClasses,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AutoAwesome as AutoAwesomeIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  RotateLeft as RotateLeftIcon,
  SignalCellularAlt,
  SignalCellularAlt1Bar,
  SignalCellularAlt2Bar,
} from '@mui/icons-material';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Iconify from '../../iconify';
import Scrollbar from '../../scrollbar/scrollbar';
import { ConfirmDialog } from '../../custom-dialog';
import EmptyContent from '../../empty-content';
import { useFeedbackDrawer } from '../../../hooks/useFeedbackDrawer';
import { getStatusImage, renderElementLink } from '../../dataTable/new-utils';
import { FeedbackExtendedDetails } from './feedback-extended-details';
import IntegrationThumbnail from '../../file-thumbnail/integration-thumbnail';
import { renderTypeOrIcon } from '../../dynamicRequestForm/utils';
import { paths } from '../../../routes/paths';
import { IntegrationQuestion } from '../../../models/integration-question';
import { IAnswers } from '../../../models/feedback';

const FeedbackDrawer = () => {
  const theme = useTheme();
  const {
    t,
    user,
    open,
    onClose,
    selectedFeedback,
    showQuestions,
    confirm,
    sendLoading,
    loading,
    feedback,
    expanded,
    setExpanded,
    expandedSummary,
    setExpandedSummary,
    handleClick,
    reSendFeedback,
    deleteFeedback,
    setSendLoading,
  } = useFeedbackDrawer();

  const margins = useMemo(() => ({ pr: 1, pl: 2 }), []);
  const datesStyle = useMemo(
    () => ({ mt: 2, ml: 1, mr: 1, color: theme.palette.text.disabled }),
    [theme]
  );

  const renderQuality = useCallback((quality: string, qualityJustification: string) => {
    let icon = <></>;
    switch (quality) {
      case 'HIGH':
        icon = <SignalCellularAlt color="success" />;
        break;
      case 'MEDIUM':
        icon = <SignalCellularAlt2Bar color="info" />;
        break;
      case 'LOW':
        icon = <SignalCellularAlt1Bar color="warning" />;
        break;
      default:
        break;
    }
    return <Tooltip title={qualityJustification}>{icon}</Tooltip>;
  }, []);

  const renderDetailsList = useMemo(
    () =>
      selectedFeedback && (
        <List sx={{ width: '100%', ...margins }}>
          {user?.dataUser?.role === 'ADMIN' && (
            <ListItem
              key="id"
              alignItems="flex-start"
              onClick={handleClick}
              sx={{ cursor: 'pointer' }}
            >
              <ListItemText primary={t('text.tables.id')} sx={{ pr: 1 }} />
              <Typography
                variant="body2"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                {selectedFeedback?.id}
              </Typography>
            </ListItem>
          )}
          <ListItem id="status" alignItems="center" sx={{ justifyContent: 'center' }}>
            <ListItemText primary={t('text.tables.status')} />
            {getStatusImage(selectedFeedback?.status, true)}
          </ListItem>
          <ListItem id="user" alignItems="center" sx={{ justifyContent: 'center' }}>
            <ListItemText primary={t('text.tables.quality')} />
            <Typography variant="body2">
              {renderQuality(
                selectedFeedback?.quality || '',
                selectedFeedback?.qualityJustification || ''
              )}
            </Typography>
          </ListItem>
          {user?.permissions?.includes('FeedbackDelete') && (
            <ListItem id="delete" alignItems="center" sx={{ justifyContent: 'end' }}>
              <Button
                onClick={reSendFeedback}
                variant="text"
                startIcon={<RotateLeftIcon />}
                sx={{
                  mt: 2,
                  mr: 2,
                  p: 1,
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                }}
              >
                {t('text.buttons.regenerate')}
              </Button>
              <Button
                onClick={confirm.onTrue}
                color="error"
                variant="text"
                startIcon={<DeleteIcon />}
                sx={{
                  color: '#dc0000',
                  mt: 2,
                  ml: 2,
                  p: 1,
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                }}
              >
                {t('text.buttons.delete')}
              </Button>
            </ListItem>
          )}
        </List>
      ),
    [selectedFeedback, user, handleClick, t, renderQuality, reSendFeedback, confirm, margins]
  );

  const renderDetailsDates = useMemo(
    () =>
      selectedFeedback && (
        <Stack direction="column" alignItems="start" justifyContent="start" width="100%">
          <Typography variant="body2" sx={datesStyle}>
            {t('text.labels.addedOn')}
            {selectedFeedback?.createdAt &&
              format(new Date(selectedFeedback?.createdAt), 'dd MMM yyyy')}{' '}
            at {selectedFeedback?.createdAt && format(new Date(selectedFeedback?.createdAt), 'p')}
          </Typography>
          <Typography variant="body2" sx={datesStyle}>
            {t('text.labels.mostResentChange')}
            {selectedFeedback?.updatedAt &&
              format(new Date(selectedFeedback?.updatedAt), 'dd MMM yyyy')}{' '}
            at {selectedFeedback?.updatedAt && format(new Date(selectedFeedback?.updatedAt), 'p')}
          </Typography>
          <Typography variant="body2" sx={datesStyle}>
            {t('text.labels.lastUpdateExternal')}
            {selectedFeedback?.externalUpdatedAt &&
              format(new Date(selectedFeedback?.externalUpdatedAt), 'dd MMM yyyy')}{' '}
            at{' '}
            {selectedFeedback?.externalUpdatedAt &&
              format(new Date(selectedFeedback?.externalUpdatedAt), 'p')}
          </Typography>
        </Stack>
      ),
    [selectedFeedback, datesStyle, t]
  );

  const renderHead = useMemo(
    () => (
      <Stack width={1}>
        <CardHeader
          title={selectedFeedback?.name}
          subheader={selectedFeedback?.identity}
          avatar={
            <IntegrationThumbnail
              file={selectedFeedback?.identity}
              sx={{ width: 30, height: 30, marginRight: 1 }}
            />
          }
          action={
            <IconButton onClick={onClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          }
          sx={{
            pb: expanded ? 0 : 1,
          }}
        />
        <Collapse in={expanded}>
          {selectedFeedback?.externalUrl && (
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              pb={1.5}
              sx={margins}
            >
              {renderElementLink(selectedFeedback.externalUrl)}
            </Stack>
          )}
          {renderDetailsList}
          <Box
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              background: theme.palette.background.neutral,
              pb: 1,
              ...margins,
            }}
          >
            {renderDetailsDates}
          </Box>
        </Collapse>
        <Divider sx={{ backgroundColor: 'transparent', mt: '-13px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 20,
              background: theme.palette.background.paper,
            }}
          >
            <ExpandMoreIcon
              onClick={() => {
                setExpanded(!expanded);
              }}
              sx={{
                transform: `rotate(${expanded ? '180deg' : '0'})`,
                transition: 'transform 0.3s ease',
                color: theme.palette.text.secondary,
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.text.primary,
                },
              }}
            />
          </Box>
        </Divider>
      </Stack>
    ),
    [
      selectedFeedback,
      onClose,
      expanded,
      theme,
      renderDetailsList,
      renderDetailsDates,
      setExpanded,
      margins,
    ]
  );

  const renderQuestions = useMemo(() => {
    const questions: {
      question: IntegrationQuestion;
      answers: IAnswers[];
    }[] = [];

    const qL = feedback?.integration?.questions?.filter((q) => q.status === 'ACTIVE') || [];
    const pL = feedback?.integration?.pages?.filter((p) => p.status === 'ACTIVE') || [];

    if (qL.length > 0 && pL.length > 0) {
      // sort pL by page order
      pL.sort((pa, pb) => pa.position - pb.position);
      // sort qL by page order
      qL.sort((qa, qb) => qa.position - qb.position);
      pL.forEach((p) => {
        qL.forEach((q) => {
          if (q.integrationPageId === p.id) {
            questions.push({
              question: q,
              answers:
                selectedFeedback?.answers?.filter(
                  (answer) => answer.key === q.ref || answer.key === `${q.ref}-extra-option`
                ) || [],
            });
            // Check if exit a followup question for this question
            const followup = selectedFeedback?.questions?.find(
              (qf) => qf.ref === `${q.ref}-followup`
            );
            if (followup) {
              questions.push({
                question: followup as IntegrationQuestion,
                answers:
                  selectedFeedback?.answers?.filter(
                    (answer) =>
                      answer.key === followup.ref || answer.key === `${followup.ref}-extra-option`
                  ) || [],
              });
            }
          }
        });
      });
    } else if (qL.length > 0 && pL.length === 0) {
      qL.forEach((q) => {
        questions.push({
          question: q,
          answers:
            selectedFeedback?.answers?.filter(
              (answer) => answer.key === q.ref || answer.key === `${q.ref}-extra-option`
            ) || [],
        });
      });
    }

    return (
      <Scrollbar sx={{ minHeight: 'auto', p: 0 }}>
        <Stack py={0} divider={<Divider sx={{ mx: 2 }} />}>
          {questions.filter((item) => item.answers.length > 0).length > 0 ? (
            questions
              .filter((item) => item.answers.length > 0)
              .map((item, index) => (
                <Stack
                  key={index}
                  py={1}
                  px={2}
                  sx={
                    item?.question.ref?.includes('followup')
                      ? {
                          // Add a border gradient to the followup questions
                          background: theme.palette.primary.lighter,
                        }
                      : {}
                  }
                >
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon>
                      {item?.question.ref?.includes('followup') ? (
                        <AutoAwesomeIcon fontSize="small" color="primary" />
                      ) : (
                        renderTypeOrIcon(item?.question.type, { height: '16px' })
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={<>{item?.question.title}</>}
                      primaryTypographyProps={{
                        variant: 'subtitle1',
                        fontSize: '0.875rem',
                        color: theme.palette.text.secondary,
                      }}
                      secondary={`${t(`text.questionType.${item?.question.type}`)}${
                        item?.question.ref?.includes('followup')
                          ? `    ${t('text.inputs.followup')}`
                          : ''
                      }`}
                      secondaryTypographyProps={{
                        variant: 'body2',
                        fontSize: '0.75rem',
                      }}
                      sx={{ flexGrow: 1 }}
                    />
                  </ListItem>
                  <Stack
                    direction="column"
                    alignItems="start"
                    justifyContent="start"
                    width="100%"
                    sx={{ pl: 5, my: 1 }}
                    spacing={2}
                  >
                    {selectedFeedback?.answers
                      ?.filter(
                        (answer) =>
                          answer.key === item?.question.ref ||
                          answer.key === `${item?.question.ref}-extra-option`
                      )
                      .map((answer) => {
                        const keys = answer.key.split('-');
                        const title = keys.slice(1).join(' ');
                        const value =
                          typeof answer.value === 'string'
                            ? [answer.value]
                            : (answer.value as string[]);
                        if (keys.length > 2 && !title.includes('followup')) {
                          return (
                            <Typography
                              variant="body2"
                              sx={{ color: theme.palette.text.secondary }}
                            >
                              {`${title}: ${(answer.value as string[]).toString()}`}
                            </Typography>
                          );
                        }
                        return value.map((val, i) => (
                          <Typography
                            key={i}
                            variant="body2"
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            {`${val}`}
                          </Typography>
                        ));
                      })}
                  </Stack>
                </Stack>
              ))
          ) : (
            <EmptyContent filled title={t('error.noData')} sx={{ py: 10 }} />
          )}
        </Stack>
      </Scrollbar>
    );
  }, [selectedFeedback, theme, t, feedback]);

  const renderOriginalText = useMemo(
    () => (
      <Stack alignItems="center" sx={{ ...margins, mb: 2 }}>
        <Accordion
          expanded={!expanded}
          onChange={() => setExpanded((prevState) => !prevState)}
          sx={{
            width: '100%',
            background: theme.palette.background.paper,
            borderRadius: '16px',
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="feedback-details-content"
            id="feedback-details-header"
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              maxHeight: '40px',
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Typography variant="subtitle2">{t('text.labels.originalText')}</Typography>
              {selectedFeedback?.identity === 'MAGICSURVEY' && !selectedFeedback?.finished && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme.palette.warning.main,
                    variant: 'body2',
                    mr: 2,
                  }}
                >
                  {`( ${t('text.labels.surveyStartedButNotCompleted')} )`}
                </Typography>
              )}
              {['MAGICFORM', 'MAGICSURVEY'].includes(selectedFeedback?.identity || '') &&
                selectedFeedback?.integrationId &&
                renderElementLink(paths.collections.detail(selectedFeedback?.integrationId))}
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: '400px', overflow: 'auto', height: 'auto', px: 0 }}>
            {loading && <Skeleton variant="rectangular" height={110} sx={{ mx: 1 }} />}
            {!loading &&
              ['MAGICFORM', 'MAGICSURVEY'].includes(selectedFeedback?.identity || '') &&
              renderQuestions}
            {!loading &&
              !['MAGICFORM', 'MAGICSURVEY'].includes(selectedFeedback?.identity || '') && (
                <Typography variant="body2" sx={{ px: 2 }}>
                  {selectedFeedback?.text}
                </Typography>
              )}
          </AccordionDetails>
        </Accordion>
      </Stack>
    ),
    [margins, expanded, theme, t, selectedFeedback, loading, renderQuestions, setExpanded]
  );

  const renderSummary = useMemo(
    () => (
      <Stack alignItems="center" sx={{ ...margins, my: 2 }}>
        <Accordion
          expanded={expandedSummary}
          onChange={() => setExpandedSummary((prevState) => !prevState)}
          sx={{
            width: '100%',
            background: theme.palette.background.paper,
            borderRadius: '16px',
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="feedback-details-content"
            id="feedback-details-header"
            sx={{ borderBottom: `1px solid ${theme.palette.divider}`, maxHeight: '40px' }}
          >
            <Typography variant="subtitle2">{t('text.labels.summary')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: '400px', overflow: 'auto', height: 'auto' }}>
            <Typography variant="body2">{selectedFeedback?.summary}</Typography>
          </AccordionDetails>
        </Accordion>
      </Stack>
    ),
    [expandedSummary, setExpandedSummary, margins, theme, t, selectedFeedback]
  );

  useEffect(() => {
    if (selectedFeedback) {
      console.log('selectedFeedback', selectedFeedback);
    }
  }, [selectedFeedback]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      slotProps={{ backdrop: { invisible: true } }}
      sx={{ [`& .${drawerClasses.paper}`]: { width: '50%', minWidth: 750 } }}
    >
      {renderHead}
      {selectedFeedback?.summary && renderSummary}
      {renderOriginalText}
      <Divider sx={{ borderStyle: 'dashed' }} />
      {loading ? (
        <CircularProgress sx={{ mx: 'auto', my: 2 }} />
      ) : (
        feedback && <FeedbackExtendedDetails feedback={feedback} showQuestions={showQuestions} />
      )}
      <ConfirmDialog
        open={confirm.value}
        onClose={() => {
          confirm.onFalse();
          setSendLoading(false);
        }}
        title={t('text.buttons.delete')}
        label={t('text.labels.confirmationDeleteFeedback')}
        closeLabel={t('text.buttons.cancel')}
        action={
          <Button
            variant="contained"
            color="error"
            disabled={sendLoading}
            onClick={deleteFeedback}
            sx={{
              backgroundColor: '#dc0000',
              color: '#fff',
            }}
          >
            {sendLoading ? t('text.labels.deleting') : t('text.buttons.delete')}
          </Button>
        }
      />
    </Drawer>
  );
};

export default React.memo(FeedbackDrawer);
