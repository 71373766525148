import React, { useMemo } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { InfoButton } from '../../infoButton/infoButton';

interface InputTextProps {
  value: any;
  update: (id: string, value: any) => void;
  id: string;
  label: string;
  defaultValue: any;
  type: 'text' | 'number' | 'password' | 'email';
  multiline?: boolean;
  placeholder?: string;
  sx?: any;
  disabled?: boolean;
  enterAction?: () => void;
  lines?: number;
  maxLength?: number;
  helperText?: string;
  error?: boolean;
  errorMessage?: string;
}

export default function InputText({
  value,
  update,
  id,
  label,
  defaultValue,
  type,
  multiline = false,
  placeholder = '',
  sx,
  disabled = false,
  enterAction,
  lines = 1,
  maxLength = 1000000,
  helperText = '',
  error = false,
  errorMessage,
}: InputTextProps) {
  const minHeight = lines * 45;
  const errorText = useMemo(
    () => (
      <Typography
        variant="subtitle2"
        color="error"
        sx={{
          fontSize: '0.8rem !important',
          textAlign: 'start',
          alignSelf: 'center',
        }}
      >
        {errorMessage}
      </Typography>
    ),
    [errorMessage]
  );

  const textColor = (theme: Theme) => {
    if (disabled) {
      return theme.palette.text.disabled;
    }
    if (error) {
      return theme.palette.error.main;
    }

    return theme.palette.text.primary;
  };

  return (
    <FormControl fullWidth error={error}>
      <InputLabel
        shrink
        sx={{
          fontSize: '1.1rem !important',
          color: (theme) => `${textColor(theme)} !important`,
          ml: -1,
          ...(helperText && { mt: -1, display: 'flex', alignItems: 'center' }),
        }}
      >
        {label}
        {helperText && helperText !== '' && (
          <InfoButton text={helperText} size="medium" buttonSize={45} iconSize={25} />
        )}
      </InputLabel>
      <TextField
        autoComplete="chrome-off"
        inputProps={{
          autoComplete: type === 'password' ? 'new-password' : 'off',
          maxLength,
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && enterAction) {
            enterAction();
          }
        }}
        multiline={multiline}
        maxRows={lines || multiline ? 3 : 1}
        rows={lines || multiline ? 3 : 1}
        id={id}
        type={type}
        variant="outlined"
        placeholder={placeholder}
        value={value || defaultValue || ''}
        onChange={(event) =>
          update(id, type === 'number' ? Number(event.target.value) : event.target.value)
        }
        disabled={disabled}
        error={error}
        helperText={error ? errorText : ''}
        sx={{
          minHeight,
          py: 2,
          ...sx,
          '& .MuiInputBase-input.Mui-disabled': {
            color: (theme) => theme.palette.text.disabled,
            borderColor: (theme) => theme.palette.text.disabled,
            cursor: 'not-allowed',
          },
        }}
      />
    </FormControl>
  );
}
