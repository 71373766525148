import { useMemo } from 'react';
// routes
import { useTranslation } from 'react-i18next';
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from '../../auth/hooks';
import { NavListProps } from '../../components/nav-section';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

export const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  connect: icon('ic_connect'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  issues: icon('ic_issues'),
  validations: icon('ic_validations'),
  requests: icon('ic_requests'),
  love: icon('ic_love'),
  inventory: icon('ic_inventory'),
  profiles: icon('ic_profiles'),
  party: icon('ic_3rd-party'),
  search: icon('ic_magic-search'),
  forms: icon('ic_magic-forms'),
  surveys: icon('ic_magic-surveys'),
  reporting: icon('ic_magic-forms'),
};

// ----------------------------------------------------------------------
const getNavList = (permissions: string[], t: any) =>
  [
    // OVERVIEW
    // ----------------------------------------------------------------------
    permissions.includes('Feedback')
      ? {
          subheader: '',
          items: [
            {
              title: t('text.pageTitles.dashboard'),
              path: paths.dashboard.root,
              icon: ICONS.dashboard,
            },
          ],
        }
      : null,
    // INTERACTIONS
    // ----------------------------------------------------------------------
    permissions.includes('Integrations') && {
      subheader: t('text.pageTitles.collection'),
      items: [
        {
          title: t('text.pageTitles.integrations'),
          path: paths.collections.EParty,
          icon: ICONS.party,
        },
        {
          title: t('text.pageTitles.survey'),
          path: paths.collections.surveys.root,
          icon: ICONS.surveys,
        },
        /*
      {
        title: t('text.pageTitles.form'),
        path: paths.collections.forms,
        icon: ICONS.forms,
      },
      */
      ],
    },
    // INSIGHTS
    // ----------------------------------------------------------------------
    permissions.includes('Feedback')
      ? {
          subheader: t('text.pageTitles.analysis'),
          items: [
            { title: t('text.pageTitles.feedbacks'), path: paths.feedback.root, icon: ICONS.file },
            {
              title: t('text.pageTitles.issues'),
              path: paths.insights.issues,
              icon: ICONS.issues,
            },
            {
              title: t('text.pageTitles.validation'),
              path: paths.insights.validations,
              icon: ICONS.validations,
            },
            /*
{
title: t('text.pageTitles.profiles'),
path: paths.insights.profiles,
icon: ICONS.profiles,
}
*/
          ],
        }
      : null,
    permissions.includes('Feedback')
      ? {
          subheader: t('text.pageTitles.reporting'),
          items: [
            permissions.includes('Request') && {
              title: t('text.pageTitles.marketProblems'),
              path: paths.insights.requests,
              icon: ICONS.requests,
            },
            permissions.includes('Request') && {
              title: t('text.pageTitles.userLove'),
              path: paths.insights.love,
              icon: ICONS.love,
            },

            permissions.includes('ReportGet') && {
              title: t('text.pageTitles.report'),
              path: paths.insights.reporting.root,
              icon: ICONS.reporting,
            },
          ],
        }
      : null,

    // CRM
    // ----------------------------------------------------------------------

    permissions.includes('CrmContactGet') ||
    permissions.includes('CampaignGet') ||
    permissions.includes('CrmOrganizationGet')
      ? {
          subheader: t('text.pageTitles.marketing'),
          items: [
            permissions.includes('CrmContactGet')
              ? { title: t('text.pageTitles.contacts'), path: paths.crm.contacts, icon: ICONS.job }
              : null,
            permissions.includes('CampaignGet')
              ? {
                  title: t('text.pageTitles.campaigns'),
                  path: paths.crm.campaign.root,
                  icon: ICONS.mail,
                }
              : null,
          ],
        }
      : null,

    // Data
    // ----------------------------------------------------------------------
    permissions.includes('Users') ||
    permissions.includes('Companies') ||
    permissions.includes('Products')
      ? {
          subheader: t('text.pageTitles.settings'),
          items: [
            permissions.includes('Users')
              ? { title: t('text.pageTitles.users'), path: paths.users.root, icon: ICONS.user }
              : null,
            permissions.includes('Companies')
              ? {
                  title: t('text.pageTitles.companies'),
                  path: paths.companies.root,
                  icon: ICONS.banking,
                }
              : null,
            permissions.includes('Products')
              ? {
                  title: t('text.pageTitles.products'),
                  path: paths.products.root,
                  icon: ICONS.inventory,
                }
              : null,
            permissions.includes('MetricsGet')
              ? { title: t('text.labels.metrics'), path: paths.metrics.root, icon: ICONS.tour }
              : null,
          ],
        }
      : null,
  ] as { subheader: string; items: NavListProps[] }[];

export function useNavData(): { subheader: string; items: NavListProps[] }[] {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const navList = getNavList(user?.permissions, t);
  // Remove empty objects in navList and the empty values in items
  navList.filter((item) => {
    if (item !== null) {
      item.items = item.items.filter((itemList) => itemList !== null);
      return item;
    }
    return false;
  });

  return useMemo(() => navList, [navList]);
}
