import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/metrics/list'));

// ----------------------------------------------------------------------

export const metricsRoutes = [
  {
    path: 'metrics',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [{ element: <IndexPage />, index: true }],
  },
];
