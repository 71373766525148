/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import { Box, Button, CircularProgress } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Scrollbar from 'src/components/scrollbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useAuthContext } from '../../auth/hooks';
import { useDataContext } from '../../components/data/context';
import { getCompanyByUser } from '../../services/companies';
import FilterName from '../../components/dataTable/elements/filterName';
import Company from '../../models/company';

// ----------------------------------------------------------------------
const getCompaniesList = (userId: string, filter: any, order: string) =>
  getCompanyByUser(userId, filter);

export default function ProjectsPopover() {
  const popover = usePopover();
  const { user } = useAuthContext();
  const { selectedCompany, updateSelectedCompanyState } = useDataContext();

  const [companiesList, setCompaniesList] = useState<Company[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [strSearch, setStrSearch] = useState<string>('');
  const urlParams = new URLSearchParams(window.location.search);

  const filter = useMemo(
    () => ({
      status: { inq: ['ACTIVE', 'BLOCKED'] },
    }),
    []
  );

  const sort = 'name ASC';

  const getCompaniesByString = useCallback(
    (id = strSearch) => {
      setLoading(true);
      getCompaniesList(
        user?.id || '',
        {
          name: id,
          ...filter,
        },
        sort
      ).then((res) => {
        setCompaniesList(res);
        setLoading(false);
      });
    },
    [strSearch]
  );

  useEffect(() => {
    if (!user) return;
    getCompaniesList(user?.id || '', filter, sort).then((res) => {
      setCompaniesList(res);
      setShow(res?.length > 1);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const selectedCompanyFromStorage = localStorage.getItem('selectedCompany');
    const companyId = urlParams.get('project');
    if (companyId && user?.dataUser?.role === 'ADMIN') {
      updateSelectedCompanyState(companyId);
    } else if (selectedCompanyFromStorage || user?.dataUser?.companyId) {
      const cId = selectedCompanyFromStorage || user?.dataUser?.companyId;
      if (!companyId || companyId !== cId) urlParams.set('project', cId);
      if (user?.dataUser?.role === 'ADMIN')
        window.history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
      updateSelectedCompanyState(cId);
    }
  }, [user]);

  const noDataMsg = () => (
    <MenuItem sx={{ p: 1 }}>
      <ListItemText
        primary="No data"
        primaryTypographyProps={{
          typography: 'subtitle2',
        }}
      />
    </MenuItem>
  );

  return (
    <>
      {show && (
        <Button
          color={popover.open ? 'primary' : 'inherit'}
          onClick={popover.onOpen}
          sx={{
            ...(popover.open && {
              bgcolor: (theme) => theme.palette.action.selected,
            }),
          }}
          size="small"
          endIcon={<ArrowDropDownIcon />}
        >
          {selectedCompany}
        </Button>
      )}

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 350, p: 2 }}>
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Projects <Typography component="span">({companiesList.length})</Typography>
        </Typography>

        <FilterName
          size="350"
          filterString={strSearch}
          onFilters={(name, value) => {
            setStrSearch(value);
            getCompaniesByString(value);
          }}
        />

        <Scrollbar sx={{ height: 320, mt: 1 }}>
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!loading && companiesList?.length === 0 && noDataMsg()}
          {!loading &&
            companiesList?.length > 0 &&
            companiesList.map((company) => (
              <MenuItem key={company.id} sx={{ p: 1 }}>
                <ListItemText
                  primary={company.name}
                  primaryTypographyProps={{
                    typography: 'subtitle2',
                    color: company.id === selectedCompany ? 'primary' : 'text.primary',
                  }}
                  onClick={() => {
                    updateSelectedCompanyState(company.id);
                    popover.onClose();
                    urlParams.set('project', company.id);
                    window.history.pushState(
                      {},
                      '',
                      `${window.location.pathname}?${urlParams.toString()}`
                    );
                    window.location.reload();
                  }}
                />
              </MenuItem>
            ))}
        </Scrollbar>
      </CustomPopover>
    </>
  );
}
