// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
// models

import { useTranslation } from 'react-i18next';
import { Profile } from '../../../models/profile';

interface Props {
  profiles: Profile[];
}

export const ProfilesItemsList = ({ profiles }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const style = { mt: 1.5, color: theme.palette.text.secondary };

  return (
    <Stack direction="column">
      {profiles.map((profile) => (
        <Stack key={profile.id} sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ flexGrow: 1, color: theme.palette.text.secondary }}>
            {profile.name}
          </Typography>
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="start"
            width="100%"
            sx={{ pb: 1 }}
          >
            <Typography variant="body2" sx={style}>
              {`${t('text.labels.email')}:  ${
                profile.email && profile.email !== 'N/A' ? profile.email : '-'
              }`}
            </Typography>
            <Typography variant="body2" sx={style}>
              {`${t('text.labels.company')}:  ${
                profile.profile_company?.length > 0 ? profile.profile_company.toString() : '-'
              }`}
            </Typography>
            <Typography variant="body2" sx={style}>
              {`${t('text.labels.gender')}:  ${
                profile.profile_gender?.length > 0 ? profile.profile_gender.toString() : '-'
              }`}
            </Typography>
            <Typography variant="body2" sx={style}>
              {`${t('text.labels.occupation')}:  ${
                profile.profile_occupation?.length > 0 ? profile.profile_occupation.toString() : '-'
              }`}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
