export const parseDate = (date: Date, end: boolean = false) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);

  return `${newDate.toISOString().slice(0, -1).split('T')[0]}T${end ? '23:59:59' : '00:00:00'}`;
};

export const clearFilterToSend = (filter: any, type?: string) => {
  if (!filter) return {};
  const filterToSend: any = {};
  if (filter.id && filter.id.length > 0) {
    switch (type) {
      case 'companies':
        filterToSend.id = filter.id;
        break;
      case 'issues':
      case 'validations':
        filterToSend['insight.id'] = filter.id;
        break;
      case 'insights':
        filterToSend['requestMessages.id'] = filter.id;
        break;
      default:
        filterToSend.id = { inq: [...filter.id] };
        break;
    }
  }
  if (filter.name) {
    switch (type) {
      case 'issues':
        filterToSend.or = [
          { 'issues.name': { ilike: encodeURIComponent(`%${filter.name}%`) } },
          { 'issues.reason': { ilike: encodeURIComponent(`%${filter.name}%`) } },
        ];
        break;
      case 'validations':
        filterToSend.or = [
          { 'values.name': { ilike: encodeURIComponent(`%${filter.name}%`) } },
          { 'values.reason': { ilike: encodeURIComponent(`%${filter.name}%`) } },
        ];
        break;
      case 'insights':
      case 'request':
        filterToSend['requestMessages.name'] = { ilike: encodeURIComponent(`%${filter.name}%`) };
        break;
      case 'feedback':
        filterToSend.or = [
          { name: { ilike: encodeURIComponent(`%${filter.name}%`) } },
          { summary: { ilike: encodeURIComponent(`%${filter.name}%`) } },
          { id: { ilike: encodeURIComponent(`%${filter.name}%`) } },
        ];
        break;
      case 'profiles':
        filterToSend.or = [
          { 'profile.name': { ilike: encodeURIComponent(`%${filter.name}%`) } },
          { 'profile.email': { ilike: encodeURIComponent(`%${filter.name}%`) } },
        ];
        break;
      case 'sessions':
        filterToSend.or = [
          { 'crmContact.name': { ilike: encodeURIComponent(`%${filter.name}%`) } },
          { 'crmContact.email': { ilike: encodeURIComponent(`%${filter.name}%`) } },
        ];
        break;
      default:
        filterToSend.name = { ilike: encodeURIComponent(`%${filter.name}%`) };
        break;
    }
  }
  if (filter.email?.length > 0) {
    switch (type) {
      case 'profiles':
        filterToSend['profile.email'] = filter.email;
        break;
      default:
        filterToSend.email = filter.email;
        break;
    }
  }
  if (filter.status?.length > 0) {
    switch (type) {
      case 'feedback':
        filterToSend.status = { inq: filter.status.map((status: any) => status?.id) };
        break;
      case 'sessions':
        filterToSend.status = { inq: filter.status };
        break;
      default:
        filterToSend.status = filter.status;
        break;
    }
  }
  if (filter.integration?.filter((integration: any) => integration?.id !== undefined).length > 0) {
    const integrations = filter.integration.filter(
      (integration: any) => integration?.id !== undefined
    );
    switch (type) {
      case 'insights':
      case 'request':
        filterToSend['insights.integration'] = {
          inq: integrations.map((integration: any) => integration?.id),
        };
        break;
      case 'issues':
      case 'validations':
        filterToSend['insight.integration'] = {
          inq: integrations.map((integration: any) => integration?.id),
        };
        break;
      case 'metrics':
        filterToSend.integration = {
          inq: integrations.map((integration: any) => integration?.id),
        };
        break;
      default:
        filterToSend.integrationId = {
          inq: integrations.map((integration: any) => integration?.id),
        };
    }
  }
  if (filter.identity?.length > 0) {
    switch (type) {
      case 'insights':
      case 'request':
        filterToSend['insights.identity'] = { inq: filter.identity };
        break;
      default:
        filterToSend.identity = { inq: filter.identity };
    }
  }
  if (filter.productId?.length > 0) {
    switch (type) {
      case 'issues':
      case 'validations':
        filterToSend['insight.product'] =
          { inq: filter.productId.map((product: any) => product?.id) } || filter.productId;
        break;
      case 'insights':
      case 'request':
        filterToSend.product =
          { inq: filter.productId.map((product: any) => product?.id) } || filter.productId;
        break;
      default:
        filterToSend.productId =
          { inq: filter.productId.map((product: any) => product?.id) } || filter.productId;
        break;
    }
  }
  if (filter.category?.filter((category: any) => category?.id !== undefined)?.length > 0) {
    switch (type) {
      case 'request':
        filterToSend['requestMessages.category'] = {
          inq:
            filter.category
              .filter((category: any) => category?.id !== undefined)
              .map((category: any) => category?.id) || filter.category,
        };
        break;
      case 'insights':
        filterToSend['insights.category'] = {
          inq:
            filter.category
              .filter((category: any) => category?.id !== undefined)
              .map((category: any) => category?.id) || filter.category,
        };
        break;
      default:
        filterToSend.category = {
          inq:
            filter.category.map((category: any) => category?.id?.trim()) ||
            filter.category.map((category: any) => category?.trim()),
        };
        break;
    }
  }

  if (filter.metrics?.filter((metric: any) => metric !== null).length > 0) {
    const parseMetrics = (m: any) =>
      m.map((metric: any) => ({
        metricsName: metric.metrics_name,
        metricsValues: metric.metrics_values,
      }));

    switch (type) {
      case 'issues':
      case 'validations':
      case 'feedback':
        filterToSend.metrics = filter.metrics;
        break;
      case 'request':
      case 'insights':
        filterToSend.metrics = parseMetrics(filter.metrics);
        break;
      case 'metrics':
        break;
      default:
        filterToSend['insights.metrics.values'] = parseMetrics(filter.metrics);
    }
  }

  if (filter.feedback?.length > 0) {
    switch (type) {
      case 'issues':
      case 'validations':
        filterToSend.id = { inq: filter.feedback.map((feedback: any) => feedback?.id) };
        break;
      case 'metrics':
        filterToSend['insight.id'] = { inq: filter.feedback.map((feedback: any) => feedback?.id) };
        break;
      case 'request':
      case 'insights':
        filterToSend['insights.feedbackId'] = {
          inq: filter.feedback.map((feedback: any) => feedback?.id),
        };
        break;
      default:
        filterToSend['insight.name'] = {
          inq: filter.feedback.map((feedback: any) => feedback?.id),
        };
    }
  }
  if (filter.integrationId) {
    filterToSend.integrationId = filter.integrationId;
  }
  if (filter.type?.length > 0) {
    filterToSend.type = filter.type;
  }
  if (filter.startDate && filter.endDate) {
    switch (type) {
      case 'insights':
      case 'request':
        filterToSend['insights.externalGeneratedAt'] = {
          between: [parseDate(filter.startDate), parseDate(filter.endDate, true)],
        };
        break;
      case 'feedback':
        filterToSend.externalGeneratedAt = {
          between: [parseDate(filter.startDate), parseDate(filter.endDate, true)],
        };
        break;
      case 'metrics':
        filterToSend['insight.externalGeneratedAt'] = {
          between: [
            filter.startDate.toISOString().slice(0, -1),
            filter.endDate.toISOString().slice(0, -1),
          ],
        };
        break;
      default:
        filterToSend['insight.externalGeneratedAt'] = {
          between: [parseDate(filter.startDate), parseDate(filter.endDate, true)],
        };
        break;
    }
  } else if (filter.startDate) {
    switch (type) {
      case 'insights':
      case 'request':
        filterToSend['insights.externalGeneratedAt'] = {
          gt: parseDate(filter.startDate),
        };
        break;
      default:
        filterToSend.externalGeneratedAt = {
          gt: parseDate(filter.startDate),
        };
        break;
    }
  } else if (filter.endDate) {
    switch (type) {
      case 'insights':
      case 'request':
        filterToSend['insights.externalGeneratedAt'] = {
          gt: parseDate(filter.endDate, true),
        };
        break;
      default:
        filterToSend.externalGeneratedAt = {
          lt: parseDate(filter.endDate, true),
        };
        break;
    }
  }
  if (filter['user.email']) {
    filterToSend['user.email'] = filter['user.email'];
  }
  if (filter['user.id']) {
    filterToSend['user.id'] = filter['user.id'];
  }
  if (filter.phase) {
    filterToSend.phase = { inq: filter.phase };
  }
  if (filter.userId) {
    filterToSend.userId = filter.userId;
  }

  if (filter.completed) {
    if (type === 'feedback') filterToSend.finished = filter.completed;
  }

  if (filter.quality?.length > 0) {
    filterToSend.quality = { inq: filter.quality };
  }

  if (filter.and) {
    filterToSend.and = filter.and;
  }
  if (filter.or) {
    filterToSend.or = filter.or;
  }

  return filterToSend;
};
